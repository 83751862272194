.buscador {
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bgInput {
    background-color: #F7F7F7;
    width: 50%;
    height: 2rem;
}
.btn {
    background-color: #0794E3;
    color: #fff;
    width: 30%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1rem;
    height: 100%;
}
.btnFav {
    position: relative;

}


@media only screen and (min-width: 481px) {
    .cardContainer {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 2rem;
    }
    .image {
        width: 100%;
        margin: 0;
    }
    .btnFav {

    }

}
@media only screen and (min-width: 769px) {
    .cardContainer {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 2rem;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .card {
        width: 30%;
        height: 30vh;
    }
    .image {
        object-fit: cover;
        object-position: center;
    }

    
}
@media only screen and (min-width: 1024px) {
    .bgInput {
        width: 30%;
        height: 2rem;
    }
    .btn {
        width: 15%;
    }
    .cardContainer {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 2rem;
    }
    .card {
        width: 30%;
        height: 50vh;
    }
}
